import { getTokenFromStorage } from './api';

export class WebSocketService {
    private static instance: WebSocketService;
    private socket: WebSocket | null = null;
    private reconnectAttempts = 0;
    private maxReconnectAttempts = 5;
    private reconnectDelay = 1000;
    private isConnecting = false;
    private refreshToken = false;

    public static getInstance(): WebSocketService {
        if (!WebSocketService.instance) {
            WebSocketService.instance = new WebSocketService();
        }
        return WebSocketService.instance;
    }

    public getSocket(): WebSocket | null {
        return this.socket;
    }

    public getMessage(callback: (message: any) => void): void {
        if (typeof callback !== 'function' || this.socket == null) return;
        this.socket.onmessage = callback;
    }

    public setRefreshToken(isRefreshing: boolean): void {
        this.refreshToken = isRefreshing;
    }

    public async connect(): Promise<WebSocket | null> {
        if (this.socket?.readyState === WebSocket.OPEN) {
            console.log('[WebSocket] Conexão já está ativa');
            return this.socket;
        }

        if (this.isConnecting) {
            console.log('[WebSocket] Conexão em andamento, aguarde...');
            return null;
        }

        this.isConnecting = true;

        try {
            const webClientToken = getTokenFromStorage();
            const access_token: string = webClientToken.access_token;

            if (!access_token) {
                console.error('[WebSocket] Token não encontrado');
                return null;
            }

            console.log('[WebSocket] Iniciando conexão...');
            const wsUrl = `${process.env.REACT_APP_SOCKET_DNS}:${process.env.REACT_APP_SOCKET_PORT}?authToken=${access_token}`;

            if (this.socket) {
                console.log('[WebSocket] Fechando conexão anterior');
                this.socket.close();
                this.socket = null;
            }

            return await new Promise((resolve, reject) => {
                this.socket = new WebSocket(wsUrl);

                const timeout = setTimeout(() => {
                    if (this.socket?.readyState !== WebSocket.OPEN) {
                        console.error('[WebSocket] Timeout na conexão');
                        this.socket?.close();
                        reject(new Error('Timeout na conexão do WebSocket'));
                    }
                }, 10000);

                this.socket.onopen = () => {
                    clearTimeout(timeout);
                    console.log('[WebSocket] Conexão estabelecida com sucesso');
                    this.reconnectAttempts = 0;
                    this.isConnecting = false;
                    resolve(this.socket);
                };

                this.socket.onclose = async (event) => {
                    console.log('fechou');
                    if (this.refreshToken) return;

                    clearTimeout(timeout);
                    console.log('[WebSocket] Conexão fechada. Código:', event.code, 'Razão:', event.reason);
                    this.isConnecting = false;
                    if (!event.wasClean) {
                        reject(new Error(`Conexão fechada inesperadamente. Código: ${event.code}`));
                    }
                    await this.handleReconnect();
                };

                this.socket.onerror = (error) => {
                    clearTimeout(timeout);
                    console.error('[WebSocket] Erro na conexão:', error);
                    this.isConnecting = false;
                    reject(error);
                };
            });
        } catch (error) {
            console.error('[WebSocket] Erro ao conectar:', error);
            return null;
        } finally {
            this.isConnecting = false;
        }
    }

    private async handleReconnect(): Promise<void> {
        if (this.reconnectAttempts >= this.maxReconnectAttempts) {
            console.error('[WebSocket] Número máximo de tentativas de reconexão atingido');
            return;
        }

        this.reconnectAttempts++;
        const delay = this.reconnectDelay * Math.pow(2, this.reconnectAttempts - 1);

        console.log(`[WebSocket] Tentativa ${this.reconnectAttempts} de ${this.maxReconnectAttempts}`);
        console.log(`[WebSocket] Próxima tentativa em ${delay}ms`);

        await new Promise((resolve) => setTimeout(resolve, delay));

        try {
            console.log('[WebSocket] Token atualizado, reconectando...');
            await this.connect();
        } catch (error) {
            console.error('[WebSocket] Erro na reconexão:', error);
            await this.handleReconnect();
        }
    }

    public close(): void {
        if (this.socket) {
            console.log('[WebSocket] Fechando conexão manualmente');
            this.socket.close();
            this.socket = null;
        }
    }
}

export const socketService = WebSocketService.getInstance();
