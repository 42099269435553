import React, { Suspense } from 'react';
import './index.css';
import ReactDOM from 'react-dom/client';
import App from './App';
import './i18n.js';
import styled from 'styled-components';
import { Loading } from '@ftdata/styleguide';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

export const ComponentLoading = styled.div`
    height: 97vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > h2 {
        color: #3b485b;
        text-align: center;
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 1.2rem */
    }
`;
const LoadingFallback = () => {
    return (
        <ComponentLoading className="loading-fallback">
            <div>
                <Loading
                    id="loading"
                    style={{
                        position: 'relative',
                        color: '#3b485b',
                    }}
                    size="xl"
                    variant="light"
                />
            </div>
            <h2>Carregando...</h2>
        </ComponentLoading>
    );
};

root.render(
    <Suspense fallback={<LoadingFallback />}>
        <App />
    </Suspense>,
);
