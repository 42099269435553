import React, { createContext, useEffect, useState, useContext, useRef } from 'react';
import { IItem, ProductActionsSocket } from '../shared/DataStructure';
import { socketService } from '../services/Socket';
import { getTokenFulltrack } from '../services/api';
import { getAddressReverse, serializeDataSocket } from '../components/Here/Tracking/utils/common';
import { useHash } from './hash';

interface IConfigurationContext {
    updateTracker: ProductActionsSocket;
    setUpdateTracker: React.Dispatch<React.SetStateAction<ProductActionsSocket>>;
}

const SocketContext = createContext<IConfigurationContext>({} as IConfigurationContext);
const SocketProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const [updateTracker, setUpdateTracker] = useState<ProductActionsSocket>({} as ProductActionsSocket);
    const buffMessageSocket = useRef<IItem[]>([]);
    const intervalBuffMessage = useRef<NodeJS.Timeout | null>(null);
    const { validHash, hashPath, setValidHash } = useHash();

    const initSocket = async () => {
        const socket = await socketService.connect();
        if (socket) {
            socketService.getMessage((message) => {
                updateData(message);
            });
        }
    };

    useEffect(() => {
        if (validHash) {
            initSocket();

            return () => {
                socketService.close();
            };
        }
    }, [validHash]);

    const updateData = async (event: MessageEvent<string>) => {
        if (!event.data) return;
        const eventSocket = JSON.parse(event.data);

        if (eventSocket.data_type == 'has_expired_token') {
            socketService.setRefreshToken(true);
            const tokenProps = await getTokenFulltrack(hashPath);
            if (tokenProps == undefined) {
                setValidHash(false);
                socketService.setRefreshToken(false);
                return;
            }

            initSocket();
            socketService.setRefreshToken(false);
        }

        if (
            eventSocket.data_type == 'lastposition' &&
            eventSocket.data.hasOwnProperty('ativo_name') &&
            String(eventSocket.data.ativo_name).length
        ) {
            const dataSocketSerialized = serializeDataSocket(eventSocket.data);
            if (!dataSocketSerialized) return;
            buffMessageSocket.current.push(dataSocketSerialized);

            if (intervalBuffMessage.current) return;
            intervalBuffMessage.current = setInterval(() => {
                if (!buffMessageSocket.current.length) return;

                getAddressReverse(buffMessageSocket.current).then((itemFormated: IItem[]) => {
                    setUpdateTracker({ data_type: eventSocket.data_type, data: itemFormated });
                    buffMessageSocket.current = [];
                });
            }, 1000 * 12); // 12seg
        }
    };

    return <SocketContext.Provider value={{ updateTracker, setUpdateTracker }}>{children}</SocketContext.Provider>;
};

function useSocket(): IConfigurationContext {
    const context = useContext(SocketContext);
    return context;
}

export { SocketProvider, useSocket };
